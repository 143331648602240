<template>
  <div>
    <FormulaBanner :title="title" :formulaCode="formulaCode" />
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{ title }}</div>
        <div class="formBody">
          <!-- 表单 -->
          <MyPatient
            :memberId="memberId"
            :selectValue="selectValue"
            @change="userChange"
          />
          <div class="box-div">
            <div class="title">
              年龄
            </div>
            <div class="box-div-dis">
              <van-radio-group
                @change="nianlingChange"
                v-model="nianling"
                direction="horizontal"
              >
                <van-radio
                  checked-color="#2F54EB"
                  style="min-width: 50px"
                  :name="0"
                  >＜2岁</van-radio
                >
                <van-radio
                  checked-color="#2F54EB"
                  style="min-width: 50px"
                  :name="1"
                  >≥2岁</van-radio
                >
              </van-radio-group>
            </div>
          </div>
          <template v-if="nianling === 0">
            <TemplateTreeList
              :type="'check'"
              :list-data="listData1"
              :selectValue="listSelect1"
              @change="listChange1"
            ></TemplateTreeList>
            <TemplateTreeList
              v-if="listSelect1.length === 0"
              :type="'check'"
              :list-data="listData11"
              :selectValue="listSelect11"
              @change="listChange11"
            ></TemplateTreeList>
          </template>
          <template v-else-if="nianling === 1">
            <TemplateTreeList
              :type="'check'"
              :list-data="listData2"
              :selectValue="listSelect2"
              @change="listChange2"
            ></TemplateTreeList>
            <TemplateTreeList
              v-if="listSelect2.length === 0"
              :type="'check'"
              :list-data="listData22"
              :selectValue="listSelect22"
              @change="listChange22"
            ></TemplateTreeList>
          </template>

          <div  v-if="compute === true"  style="margin: 16px;display: flex;justify-content: center;">
            <van-button
              class="formula-template-button"
              block
              type="info"
              @click="submit"
              style="width:90px"
              >提交</van-button
            >
          </div>
          <!-- 计算结果 -->
          <!-- <div class="CalculationResultsBox" v-if="countText || countText !== ''">
            <div class="CalculationResults"><span>计算结果</span></div>
            <div>
              <div class="CalculationDetail"><span></span> {{countText}}</div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- 计算结果 -->
      <CalculationResults
        v-if="show"
        :countText="countText"
        :countTextList="countTextList"
      ></CalculationResults>
      <!-- 后台设置的值 -->
      <FormulaExplain :explain-data="tableData.explain"></FormulaExplain>
      <!-- 参考文献 -->
      <FormulaReferences
        :references-data="tableData.references"
      ></FormulaReferences>
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        title="应激指数"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import MyPatient from "@/components/MyPatient.vue";
import FormulaBanner from "@/components/FormulaBanner.vue";
import { Toast } from "vant";
import { getAdmins } from "@/utils/adminDate";
import { setById } from "@/service/base";
import {
  getFormula,
  getFormulaHuiXian,
  postFormula,
} from "@/service/BodyMassIndex_api"; //
import FormulaExplain from "@/components/template1/FormulaExplain";
import FormulaReferences from "@/components/template1/FormulaReferences";
import TemplateTreeList from "@/components/Template3/TemplateTreeList";
import router from "@/router";
import CalculationResults from "@/components/CalculationResults";
export default {
  name: "medicalSearch",
  data() {
    return {
      compute:true,
      show: false,
      countTextList: [],
      listSelect1: [],
      listSelect11: [],
      listSelect2: [],
      listSelect22: [],
      listData1: [
        {
          title: "异常GCS",
          tips: "Glasgow昏迷评分＜15分",
        },
        {
          title: "触诊可及的颅骨骨折",
        },
        {
          title: "精神状态改变表现",
          tips:
            "符合以下任意一项：焦虑、嗜睡、反复性的问题、 语言交流中反应迟钝",
        },
      ],
      listData11: [
        {
          title: "枕部、顶部、颞部头皮血肿",
        },
        {
          title: "LOC持续≥5秒",
          tips: "LOC = 意识丧失",
        },
        {
          title: "严重的致伤机制",
          tips:
            "定义为：\n" +
            " 机动车撞击致患者被抛出、同行人员的死亡、或翻滚\n" +
            " 步行或未戴头盔骑自行车时受到机动车撞击\n" +
            " 从 1.5 m （5 英尺）或更高的高度跌落\n" +
            " 或头部受到高冲击力物体的撞击",
        },
        {
          title: "行为异常",
        },
      ],
      listData2: [
        {
          title: "异常GCS",
          tips: "Glasgow昏迷评分＜15分",
        },
        {
          title: "触诊可及的颅骨骨折",
        },
        {
          title: "精神状态改变表现",
          tips:
            "符合以下任意一项：焦虑、嗜睡、反复性的问题、 语言交流中反应迟钝",
        },
      ],
      listData22: [
        {
          title: "枕部、顶部、颞部头皮血肿",
        },
        {
          title: "曾有LOC",
          tips: "LOC = 意识丧失",
        },
        {
          title: "严重的致伤机制",
          tips:
            "定义为：机动车撞击致患者被抛出、同行人员的死亡、或翻滚步行或未戴头盔骑自行车时受到机动车撞击从 1.5 m （5 英尺）或更高的高度跌落或头部受到高冲击力物体的撞击",
        },
        {
          title: "剧烈头痛",
        },
      ],
      formulaCode: "",
      shengao: "",
      yingjizhishu: "",
      title: "",
      memberId: "1111",
      selectValue: "",
      username: "",
      value: "",
      showPopover: false,
      showPicker: false,
      columns: ["正常或轻伤", "中度创伤", "重度创伤"],
      nianling: 0,
      xingbie: null,
      tableData: {},
      countText: "",
      patientId: null,
      result: null,
      yingjizhishuzhi: "",
      tizhong: "",
      xueqingnanongdu: "",
    };
  },
  components: {
    FormulaBanner,
    MyPatient,
    FormulaExplain,
    FormulaReferences,
    TemplateTreeList,
    CalculationResults,
  },
  async created() {
    await this.test();
    await this.getTableData();
  },

  methods: {
    nianlingChange() {
      this.show = false;
      this.listSelect1 = [];
      this.listSelect11 = [];
      this.listSelect2 = [];
      this.listSelect22 = [];
    },
    listChange1(result) {
      this.show = false;
      this.listSelect1 = result;
      this.listSelect11 = [];
    },
    listChange11(result) {
      this.show = false;
      this.listSelect11 = result;
    },
    listChange2(result) {
      this.show = false;
      this.listSelect2 = result;
      this.listSelect22 = [];
    },
    listChange22(result) {
      this.show = false;
      this.listSelect22 = result;
    },
    // 计算结果
    coumputTetxt() {
      if (this.listSelect1.length > 0 || this.listSelect2.length > 0) {
        this.countText = "推荐进行CT检查";
      } else if (this.listSelect11.length > 0 || this.listSelect22.length > 0) {
        this.countText = "推荐留院观察或进行CT检查";
      } else if (
        this.listSelect2.length == 0 &&
        this.listSelect11.length == 0 &&
        this.listSelect22.length == 0 &&
        this.listSelect1.length == 0
      ) {
        this.countText = "不推荐进行CT检查";
      }
      this.result = this.countText;
    },
    async test() {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.$route.query.memberId,
        this.$route.query.channel
      );
      this.channel = arrays.os;
      this.version = arrays.version;
      this.ip = arrays.ip;
      await setById(arrays);
    },
    async getTableData() {
      // 参考文献和说明
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: router.currentRoute.path.substring(
          9,
          router.currentRoute.path.Length
        ),
      };
      const res = await getFormula(data.channel, data.code);
      if (res.code === 0) {
        this.tableData = res.data;
        this.formulaCode = res.data.code;
        this.title = res.data.chineseName;
      }
      if (
        this.$router.currentRoute.query.location === "YXGS_RECORD_LIST" &&
        this.$router.currentRoute.query.logId
      ) {
        // this.compute = false
        const data = {
          id: this.$router.currentRoute.query.logId,
        };
        const res = await getFormulaHuiXian(data);
        if (res.code === 0) {
          this.nianling = res.data.content.nianling === "＜2岁" ? 0 : 1;
          setTimeout(() => {
            if (res.data.content.nianling === "＜2岁") {
              for (let i = 0; i < this.listData1.length; i++) {
                for (let j = 0; j < res.data.content.value.length; j++) {
                  if (this.listSelect1.length > 0) break;
                  if (res.data.content.value[j] === this.listData1[i].title) {
                    this.listSelect1 = res.data.content.value;
                    break;
                  }
                }
              }
              if (this.listSelect1.length === 0)
                for (let i = 0; i < this.listData11.length; i++) {
                  for (let j = 0; j < res.data.content.value.length; j++) {
                    if (this.listSelect11.length > 0) break;
                    if (
                      res.data.content.value[j] === this.listData11[i].title
                    ) {
                      this.listSelect11 = res.data.content.value;
                      break;
                    }
                  }
                }
            } else if (res.data.content.nianling === "≥2岁") {
              for (let i = 0; i < this.listData2.length; i++) {
                for (let j = 0; j < res.data.content.value.length; j++) {
                  if (this.listSelect2.length > 0) break;
                  if (res.data.content.value[j] === this.listData2[i].title) {
                    this.listSelect2 = res.data.content.value;
                    break;
                  }
                }
              }
              if (this.listSelect2.length === 0)
                for (let i = 0; i < this.listData22.length; i++) {
                  for (let j = 0; j < res.data.content.value.length; j++) {
                    if (this.listSelect22.length > 0) break;
                    if (
                      res.data.content.value[j] === this.listData22[i].title
                    ) {
                      this.listSelect22 = res.data.content.value;
                      break;
                    }
                  }
                }
            }
            if (res.data.patientId)
              this.selectValue = {
                id: res.data.patientId,
                name: res.data.patientName,
              };
            this.patientId = res.data.patientId;
            this.coumputTetxt();
          }, 1);
          this.$nextTick(() => {
            this.show = true;
          });
        }
      }
    },
    async submit() {
      // if (!this.listSelect.length || this.listSelect.length === 0) {
      //   Toast.fail('请至少选择一项')
      //   return
      // }
      this.coumputTetxt();
      this.show = true;
      const data = {
        id:
          this.$router.currentRoute.query.location === "YXGS_RECORD_LIST"
            ? this.$router.currentRoute.query.logId
            : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          nianling: this.nianling === 0 ? "＜2岁" : "≥2岁",
          value: [
            ...this.listSelect1,
            ...this.listSelect2,
            ...this.listSelect22,
            ...this.listSelect11,
          ],
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {
          // value:this.result
          result: this.countText,
          DisplayResults:this.countText
        },
      };
      // console.log("数据",data)
      const res = await postFormula(data);
      if (res.code === 0) {
        Toast.success("提交成功");
        this.show = true;
      } else {
        Toast.fail(res.message);
      }
    },
    calculateAge(birthDateString) {
      const today = new Date();
      const birthDate = new Date(birthDateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      // 如果今天的日期还没到生日月份和日期，则年龄减一
      if (
        today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() &&
          today.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return age;
    },
    yingjiClick() {
      this.showPicker = true;
    },
    onConfirm(value) {
      this.yingjizhishu = value;
      this.showPicker = false;
    },
    onCancel() {
      this.showPicker = false;
    },
    userChange(data) {
      let count = 0;
      if (data.birthday && data.birthday !== "") {
        count = this.calculateAge(data.birthday);
      } else {
        count = 0;
      }
      if (count >= 1) {
        this.nianling = 1;
      } else {
        this.nianling = 0;
      }
      this.xingbie = data.sex;
      this.patientId = data.id;
      this.selectValue = data;
    },
  },
};
</script>

<style scoped lang="scss">
.index {
  background: #f6f6f6;
  padding: 10px 15px;
  height: 100vh;
  .StandardDescription {
    margin-top: 10px;
    .top_nav {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .StandardDeatil {
      width: 100%;
      padding: 15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .top_nav {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody {
    background-color: #fff;
    padding-bottom: 10px;
    .CalculationResultsBox {
      padding: 0 8px;
      .CalculationResults {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }
      .CalculationDetail {
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom: 10px;
        background-color: #f6f6f6;
        // height: 16px;
        span {
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
  .titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;
    .titlesName {
      font-size: 16px;
      i {
        color: red;
      }
    }
    .information {
      font-size: 16px;
      margin-right: 4px;
      .PleaseSelect {
        color: #b9b9b9;
      }
      .vanCell {
        width: 50px;
        padding: 0;
        height: 100%;
      }
    }
    .vBotton {
      width: 60px;
    }
    .unit {
      height: 50px;
      //   line-height: 50px;
      display: flex;
      align-items: center;
      font-size: 16px;
      width: 20px;
    }
    .vanIcon {
      font-size: 13px;
      color: #a8a8a8;
      font-weight: 650;
    }
  }
}
::v-deep .van-cell {
  padding: 0px 0px;
}
::v-deep .van-field__control {
  height: 50px;
  font-size: 16px;
}
::v-deep .van-field__error-message {
  position: absolute;
  top: 30px;
  left: 10px;
}
.StandardDeatil2 {
  width: 100%;
  padding: 15px 10px;
  padding-bottom: 1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;
  .references {
    margin-bottom: 15px;
  }
}
.box-div {
  display: flex;
  padding: 10px 10px;
  border-bottom: 1px solid #f0f0f0;
  align-items: center;
  justify-content: space-between;
}

.box-div-dis {
  display: flex;
  color: #9a9a9a;
  flex-wrap: wrap;
  //margin-top: 15px;
}
.box-div-dis-zi {
  display: flex;
  margin: 12px 5px;
}
</style>
